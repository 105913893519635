import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import HeroGIF from "../../images/niro-capital.svg";
import EngagementIcon from "../../icons/engagement.svg";
import ValueIcon from "../../icons/value.svg";
import RevenueIcon from "../../icons/revenue.svg";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals("ScrollTrigger", ScrollTrigger);

const StyledWrapper = styled.section`
  padding-top: 2rem;

  h1 {
    font-size: 64px;
    line-height: 0.9em;
  }

  h2 {
    font-size: 30px;
  }

  svg {
    height: 75px;
  }

  .phoneWrapper {
    position: relative;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    img,
    .phoneScreen {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .phone {
      z-index: 5;
    }
    img {
      z-index: 5;
      width: 160px;
    }
    .phoneScreen {
      background-color: var(--orange);
      width: 155px;
      height: 315px;
      z-index: 2;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        height: unset;
      }
    }
    .circle {
      opacity: 0;
      width: 320px;
      height: 320px;
      background-color: var(--blue);
      position: absolute;
      left: 50%;
      top: 10.5%;
      border-radius: 100%;
    }
  }

  .text {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const Hero = () => {
  const title1 = useRef(null); //Meet
  const title2 = useRef(null); //Niro
  const text = useRef(null); //Meet Niro
  const phoneRef = useRef(null); //iPhone
  const circle = useRef(null); //Circle
  const otherText = useRef(null); //Text under Meet Niro

  useLayoutEffect(() => {
    const startAnim = gsap.timeline();
    startAnim
      .fromTo(text.current, { y: "-300" }, { y: "-300" })
      .fromTo(
        title1.current,
        { xPercent: "-200" },
        { xPercent: "0", duration: "1", ease: "expo.out" },
        "<"
      )
      .fromTo(
        title2.current,
        { xPercent: "200" },
        { xPercent: "0", duration: "1", ease: "expo.out" },
        "<"
      )
      .to(title1.current, { fontSize: 84, duration: 0.5 })
      .to(title2.current, { fontSize: 84, duration: 0.5 }, "<")
      .fromTo(
        text.current,
        { y: "-300" },
        { y: "0", ease: "power2.inOut" },
        ">0.75"
      )
      .fromTo(
        circle.current,
        { scale: 0, opacity: 0, translateX: "-50%" },
        {
          scale: 1,
          opacity: 1,
          translateX: "-50%",
          duration: "1.5",
          ease: "power4.out",
        },
        "<"
      )
      .fromTo(
        phoneRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 1.5, ease: "power4.out" },
        "<"
      )
      .fromTo(
        otherText.current,
        { opacity: 0 },
        { opacity: 1, duration: 1.5, ease: "power4.out" },
        "<"
      );
  }, []);

  return (
    <StyledWrapper>
      <div className="container">
        <div className="row position-relative mb-3 overflow-hidden">
          <div className="phoneWrapper">
            <div className="phone" ref={phoneRef}>
              <img src={HeroGIF} alt="" />
            </div>

            <div className="circle" ref={circle}></div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="text" ref={text}>
            <h1 ref={title1}>Meet</h1>
            <h1 ref={title2}>
              Niro
            </h1>
          </div>
        </div>
        <div ref={otherText}>
          <div className="row text-center mb-5 pb-md-2">
            <h2>
              Personalised, Embedded,
              <br />
              Frictionless Finance
            </h2>
          </div>

          <div className="row text-center points">
            <div className="col-md-4 d-flex flex-column align-items-center mb-md-0 mb-5">
              <EngagementIcon />
              <h5 className="my-2">Drive Engagement</h5>
              <p>
                Offer users a multitude of competitive financial services
                products to engage more deeply with your app.
              </p>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center mb-md-0 mb-5">
              <ValueIcon />
              <h5 className="my-2">Create Value</h5>
              <p>
                Leverage user engagement and data to offer financial products
                that are competitive, and frictionless.
              </p>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <RevenueIcon />
              <h5 className="my-2">Generate Revenue</h5>
              <p>
                Build your own financial services franchise with limited (or no)
                cost.
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Hero;
