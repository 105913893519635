import { graphql, useStaticQuery } from 'gatsby';
import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../components/Home/Hero";
import HeroM from "../components/Home/HeroM";
import InfoSection from "../components/Home/InfoSection";
import JoinUs from "../components/Home/JoinUs";
import Layout from "../components/Layout";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
        query {
          site{
              siteMetadata {
              title
              description
              keywords
              }
          }
        }
    `);

  const { title, description, keywords } = data.site.siteMetadata;
  return (
    <>
      <Helmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name="keywords" content={keywords}/>
        {/* facebook domain verification  */}
        <meta name="facebook-domain-verification" content="ll2dgljx097fsg3ux68zuhncrzgpy4" />
      </Helmet>
      <Layout>
        <Hero />
        <HeroM />
        <InfoSection />
        <JoinUs />
      </Layout>
    </>
  )
}

export default IndexPage
