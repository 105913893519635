import React from "react";
import styled from "styled-components";
import MedalIcon from "../../icons/medal.svg";
import TrophyIcon from "../../icons/trophy.svg";
import FlagIcon from "../../icons/flag.svg";
import ChartIcon from "../../icons/chart.svg";

const StyledWrapper = styled.section`
  background-color: var(--blueChalk);
  padding: 4.3rem 0;

  h4 {
    margin-bottom: 0.25em;
  }

  button {
    background-color: var(--blueChalk);
    color: white;
    border: none;
    padding: 0.75em 4.5em;
    border-radius: 50px;
  }

  @media (max-width: 768px) {
    padding: 3rem 0;
    h4 {
      font-size: 21px;
    }
  }
`;

const JoinUs = () => {
  return (
    <StyledWrapper>
      <div className="container">
        <div className="row mb-5 pb-md-5">
          <h2 className="text-center">Why Join Us?</h2>
        </div>

        <div className="row mb-md-5 pb-md-5 justify-content-evenly">
          <div className="col-md-5 d-flex mb-md-0 mb-5">
            <MedalIcon />
            <div className="ms-3">
              <h4>Funded startup with marquee investors</h4>
              <p>
                We are proudly backed by leading Angel
                investors in the fintech space. Speak to us to know more.
              </p>
            </div>
          </div>
          <div className="col-md-5 d-flex mb-md-0 mb-5">
            <TrophyIcon />
            <div className="ms-3">
              <h4>Accomplished Team</h4>
              <p>
                A team of accomplished people with the necessary experience,
                expertise and the skill to build a successful company.
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-evenly mb-5 pb-md-5">
          <div className="col-md-5 d-flex mb-md-0 mb-5">
            <FlagIcon />
            <div className="ms-3">
              <h4>Ownership</h4>
              <p>
                A culture of ownership with a very generous, fair ESOP
                compensation (no nasty clauses, we are all chasing success
                together) for all our employees.
              </p>
            </div>
          </div>
          <div className="col-md-5 d-flex">
            <ChartIcon />
            <div className="ms-3">
              <h4>Career Growth</h4>
              <p>
                We take the growth of our employees very seriously. We take your
                aspirations very seriously, and ensure that you are tracking
                towards your goals as much as possible.
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default JoinUs;
