import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useEmblaCarousel } from "embla-carousel/react";
import { useRecursiveTimeout } from "./useRecursiveTimeout";
import CircleArrow from "../../icons/CircleArrow.svg";
import { IconButton } from "@material-ui/core";

const staticContent = [
  {
    title: "Personalised, Competitive Offerings",
    desc: "Niro works with you to create best-in-class credit products that are personalised, flexible and competitive, driving take-rates & scale.",
  },
  {
    title: "Simple Integration, Frictionless Embedded Delivery",
    desc: "Use our APIs or SDKs to embed credit offerings in as little as 2 weeks. Simple, fast, fully-digital end-to-end journeys ensure healthy funnels and happy customers.",
  },
  {
    title: "Drive Revenue & Engagement",
    desc: "Generate significant revenue through seamless credit distribution, creating healthy customer engagement in the process.",
  },
];

const StyledWrapper = styled.section`
  padding-top: 4rem;
  @media (max-width: 768px) {
    padding-top: 4rem;
    h4 {
      font-size: 21px;
    }
  }
`;

const DesktopWrapper = styled.section`
  h2 {
    font-weight: 800;
  }

  h4 {
    font-weight: 500;
    font-size: 30px;
  }

  p {
    font-size: 18px;
  }

  .tile {
    display: flex;
    cursor: pointer;
    padding: 3rem;
    flex-direction: column;
    background-color: var(--blueChalk);
		border-radius: 32px;
		margin-bottom: 1rem;
    &:last-child {
      margin-bottom: unset;
    }
  }

  .selected {
    background-color: var(--babyBlue);
  }

  .embla {
    overflow: hidden;
    border-radius: 16px;
  }
  .embla__container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    column-gap: 2.5rem;
    & img {
      width: 85%;
    }
  }
`;

const MobileWrapper = styled.section`
  h2 {
    font-weight: 800;
  }

  h4 {
    font-weight: 500;
    font-size: 30px;
  }

  h5 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  .infoCard {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--blueChalk);

    .text {
      display: flex;
      padding: 1.5rem;
      flex-direction: column;
    }

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5rem;
      border-radius: 10px 10px 0 0;
      border: 2px solid var(--peach);
      background-color: white;
      & img {
        height: 50vw;
      }
    }
  }

	.font-normal {
		font-weight: 400 !important;
	}

  svg {
    width: 40px;
    height: 40px;
  }

  .embla {
    overflow: hidden;
    border-radius: 10px;
  }
  .embla__container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    column-gap: 2.5rem;
  }
`;

const Desktop = () => {
  const [index, setIndex] = useState(0);
  const [emblaRef, embla] = useEmblaCarousel({
    align: "start",
    draggable: false,
  });

  const autoplay = useCallback(() => {
    if (!embla) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
      setIndex(embla.selectedScrollSnap());
    } else {
      embla.scrollTo(0);
      setIndex(0);
    }
  }, [embla]);

  const pause = (i) => {
    stop();
    setIndex(i);
    embla.scrollTo(i);
  };

  const AUTOPLAY_INTERVAL = 4000;
  const { play, stop } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL);

  useEffect(() => {
    play();
  }, [play]);

  return (
    <DesktopWrapper className="d-lg-block d-none">
      <div className="row align-items-center justify-content-center" ref={emblaRef}>
        {/* <div className="col-5 embla" ref={emblaRef}>
          <div className="embla__container">
            <img src={img1} alt="" />
            <img src={img2} alt="" />
            <img src={img3} alt="" />
          </div>
        </div> */}

        <div className="col-7">
          {staticContent.map((item, i) => (
            <div
              key={"step" + i}
              className={`tile ${index === i && "selected"}`}
              onMouseOver={() => pause(i)}
              onFocus={() => pause(i)}
              onClick={() => pause(i)}
              onMouseLeave={() => play()}
              onBlur={() => play()}
            >
              <h5 className="mb-3">{item.title}</h5>
              <p>{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </DesktopWrapper>
  );
};

const Mobile = () => {
  const [emblaRef, embla] = useEmblaCarousel({ align: "start" });

  const scrollPrev = () => {
    if (!embla) return;
    !embla.canScrollPrev()
      ? embla.scrollTo(staticContent.length - 1)
      : embla.scrollPrev();
  };

  const scrollNext = () => {
    if (!embla) return;
    !embla.canScrollNext() ? embla.scrollTo(0) : embla.scrollNext();
  };

  return (
    <MobileWrapper className="d-lg-none d-block">
      <div className="row mb-3">
        <div className="col-12 embla" ref={emblaRef}>
          <div className="embla__container">
            {staticContent.map((item, i) => (
              <div className="infoCard" key={"infoCard" + i}>
                {/* <div className="image">
                  <img src={item.img} alt="" className="img-fluid" />
                </div> */}
                <div className="text">
                  <h5 className="mb-3">{item.title}</h5>
                  <p>{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row px-1">
        <div className="col-12">
          <IconButton
            aria-label="Previous"
            size="small"
            classes={{ root: "ms-3 me-3" }}
          >
            <CircleArrow onClick={() => scrollPrev()} />
          </IconButton>

          <IconButton aria-label="Next" size="small">
            <CircleArrow
              style={{ transform: "rotate(180deg)" }}
              onClick={() => scrollNext()}
            />
          </IconButton>
        </div>
      </div>
    </MobileWrapper>
  );
};

const InfoSection = () => {
  return (
    <StyledWrapper>
      <div className="container">
        <div className="row text-center mb-5">
          <div className="col-lg-7 col-md-10 mx-auto">
            <h2 className="mb-4">Enter the world of fintech</h2>
            <p className="font-normal">
              Any consumer platform can become a provider of competitive and
              frictionless credit products.
            </p>
          </div>
        </div>

        <Desktop />
        <Mobile />
      </div>
    </StyledWrapper>
  );
};

export default InfoSection;
