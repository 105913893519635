import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import HeroGIF from "../../images/niro-capital.svg";
import EngagementIcon from "../../icons/engagement.svg";
import ValueIcon from "../../icons/value.svg";
import RevenueIcon from "../../icons/revenue.svg";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals("ScrollTrigger", ScrollTrigger);

const StyledWrapper = styled.section`
  padding-top: 4rem;

  h1 {
    font-size: 138px;
    display: inline-block;
    width: max-content;
  }

  svg {
    height: 75px;
  }

  .phoneWrapper {
    position: relative;
    height: 560px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 300px;
    img,
    .phoneScreen {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .phone {
      z-index: 5;
    }
    img {
      z-index: 5;
      width: 280px;
    }
    .phoneScreen {
      background-color: var(--orange);
      width: 270px;
      height: 560px;
      z-index: 2;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        height: unset;
      }
    }
    .circle {
      opacity: 0;
      width: 600px;
      height: 600px;
      background-color: var(--blue);
      position: absolute;
      left: 50%;
      top: 12.5%;
      border-radius: 100%;
    }
  }

  .text {
    position: absolute;
    top: 15%;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 1048px) {
    .phoneWrapper {
      height: 400px;
      img {
        width: 200px;
      }
      .phoneScreen {
        width: 195px;
        height: 395px;
      }
      .circle {
        width: 400px;
        height: 400px;
        top: 25%;
      }
    }
  }
  @media (max-width: 992px) {
    h1 {
      font-size: 98px;
    }
    .phoneWrapper {
      margin-bottom: 350px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const Hero = () => {
  const title1 = useRef(null); //Meet
  const title2 = useRef(null); //Niro
  const text = useRef(null); //Meet Niro
  const phoneRef = useRef(null); //iPhone
  const circle = useRef(null); //Circle

  useLayoutEffect(() => {
    const clientWidth = window.innerWidth;
    let x_t1 = Math.floor(-clientWidth * 0.163); //x of Title 1
    let x_t2 = Math.floor(clientWidth * 0.095); //x of Title 2
    x_t1 = x_t1 > -210 ? x_t1 : -210;
    x_t2 = x_t2 > 140 ? 140 : x_t2;

    const startAnim = gsap.timeline();

    const scrollAnim = gsap.timeline({
      scrollTrigger: {
        trigger: text.current,
        scrub: 0.5,
        pin: true,
        start: "top top+=260",
        end: "bottom+=200 top",
      },
      x: 0,
    });

    const masterTL = gsap.timeline();
    masterTL.add(scrollAnim).add(startAnim);
    masterTL.delay(0.2);
    scrollAnim
      .from(title1.current, { x: x_t1 })
      .from(title2.current, { x: x_t2 }, "<");

    startAnim
      .fromTo(
        title1.current,
        { x: 0 },
        { x: x_t1, duration: "1", ease: "power2.out" }
      )
      .fromTo(
        title2.current,
        { x: 0 },
        { x: x_t2, duration: "1", ease: "power2.out" },
        "<"
      )
      .from(phoneRef.current, { alpha: 0, duration: "2", ease: "power4.out" })
      .fromTo(
        circle.current,
        { scale: 0, alpha: 0, translateX: "-50%" },
        {
          scale: 1,
          alpha: 1,
          translateX: "-50%",
          duration: "1.5",
          ease: "power4.out",
        },
        "<"
      );

    scrollAnim.scrollTrigger.disable();
    // simulate a delay (like waiting for a loader to finish)
    gsap.delayedCall(1, () => scrollAnim.scrollTrigger.enable());
  }, []);

  return (
    <StyledWrapper>
      <div className="container">
        <div className="row position-relative mb-3">
          <div className="phoneWrapper">
            <div className="phone" ref={phoneRef}>
              <img src={HeroGIF} alt="" />
            </div>

            <div className="circle" ref={circle}></div>

            <div className="text" ref={text}>
              <h1 ref={title1}>Meet</h1>
              <h1 ref={title2}>
                &nbsp;Niro
              </h1>
            </div>
          </div>
        </div>

        <div className="row text-center mt-2 mb-5 pb-md-2">
          <h2>
            Personalised, Embedded,
            <br />
            Frictionless Finance
          </h2>
        </div>

        <div className="row text-center align-items-start justify-content-evenly">
          <div className="col-md-3 d-flex flex-column align-items-center mb-md-0 mb-5">
            <EngagementIcon />
            <h5 className="my-2">Drive Engagement</h5>
            <p>
              Offer users a multitude of competitive financial services products
              to engage more deeply with your app.
            </p>
          </div>
          <div className="col-md-3 d-flex flex-column align-items-center mb-md-0 mb-5">
            <ValueIcon />
            <h5 className="my-2">Create Value</h5>
            <p>
              Leverage user engagement and data to offer financial products that
              are competitive, and frictionless.
            </p>
          </div>
          <div className="col-md-3 d-flex flex-column align-items-center">
            <RevenueIcon />
            <h5 className="my-2">Generate Revenue</h5>
            <p>
              Build your own financial services franchise with limited (or no)
              cost.
            </p>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Hero;
